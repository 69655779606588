html {
  scroll-behaviour: smooth;
}
* {
  font-family: "Patrick Hand SC", cursive !important;
}

.main-container-front {
  position: relative;
}
.main-container {
  position: relative;
}
.section-collection{
  background-image: url('./images/peoplebg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.girl-image-cont{
  margin-top: 30px;
}
.girl-image-cont img{
  width:100%;
}
.collection-link{
  font-size: 20px !important;
  background-color: #333 !important;
  color:#e9cf3a;
  border:1px solid transparent;
}
.collection-link:hover{
  border:1px solid #e9cf3a;
  color:#e9cf3a;
}



.main-bg {
  width: 100%;
  padding: 0px !important;
}

.main-image img {
  position: absolute;
  top: 10%;
  width: 100% !important;
}

.m-text {
  text-align: center !important;
  position: absolute;
  top: 53%;
  left: 23%;
}

.main-video {
  width: 800px;
  height: 450px;
}

.map-text {
  width: 100%;
  background-color: #e9cf3a;
  color: #333;
  margin-top: 30px;
  border-radius: 20px;
  height: 350px;
  padding: 10px 5px 10px 15px;
  
  /* clip-path: polygon(0 6%, 100% 0, 95% 93%, 0 100%); */
}
.colection-text {
  padding-right: 15px;
  max-height: 330px;
  font-size: 26px;
  text-align: justify;
  overflow-y: scroll;
  text-overflow: ellipsis;
}
.collection-content{
  margin-bottom: 20px;
}
.collection-content strong{
  display: block;
  margin: 10px;
  color:#2F3A8F;
  font-size: 20px;
  max-width: 500px;
}
.colection-title {
  text-align: center;
  position: absolute;
  top: 5%;
  left: 25%;
  color: rgb(56, 56, 56);
  font-weight: 700;
  font-size: 50px;
}

.purple-container {
  background-color: #a390bb;
  width: 100%;
  padding-bottom: 70px;
}
.green-container{
  background-color: #5ac57b;
  padding-bottom:30px;
}
.section-title{
  padding: 10px 0% 0% 0%;
  text-transform: uppercase;
  font-size: 50px;
}
.font-white{
  color: #f0f3f5;
}
.section-text{
  padding: 4% 0% 6% 0%;
  font-size: 26px;
  margin-bottom: 0rem;
}
.card-link{
  font-size:26px;
  text-decoration: none;
  background-color:#e9cf3a;
  display: inline-block;
  padding:10px 25px;
  margin-top: 15px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  transition: all 0.2s ease-in-out;
}
.card-link:hover{
  box-shadow: none;
}
.square-card{
  color: #333;
  border: 1px solid #5ac57b;
  padding:50px;
  text-align: center;
  margin:30px 20px;
  height:300px;
}
.card-heading{
  font-size: 32px;
  color: #ffddb4;
}
.card-desc{
  font-size: 20px;
  color: #5ac57b;
}
.card-link{
  font-size:26px;
  text-decoration: none;
  background-color:#e9cf3a;
  display: inline-block;
  padding:10px 25px;
  margin-top: 15px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  transition: all 0.2s ease-in-out;
}
.card-link:hover{
  box-shadow: none;
}
.section-container{
  padding:50px;
}
.bg-blue{
  background-color: #0161c2;
}
.square-card{
  padding:30px;
  margin:50px 20px;
  height:250px;
}
.card-heading{
  font-size: 32px;
  color: #ffddb4;
}
.card-heading{
  font-size: 28px;
}
.card-desc{
  font-size: 18px;
}
.card-link{
  font-size:26px;
  padding:10px 25px;
  }
.section-heading {
  padding: 50px 10px;
  margin-bottom: 0px;
  text-align: center;
  text-transform: uppercase;
  font-size: 50px;
}
.hdng-light{
  color:#f0f3f5 !important;
}
.roadmap-col{
  border-right:2px solid rgb(238, 98, 5);
  position: relative;
}
.roadmap-col::before{
  content: url('./images/objective-icon.png');
  transform: scale(0.8);
  position: absolute;
  right:-24px;
  top:-30px;
  color:#a390bb;
}
.final-goal::after{
  content: url('./images/final-goal.png');
  position: absolute;
  right:-25px;
  bottom: -55px;
}
.goal-desc-container{
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.roadmap-content{
  display:flex;
  flex-direction: column;
  margin-bottom: 60px;
  margin-top: -30px;
} 
.right{
  align-items:flex-end;
  margin-right: 60px;
}
.right p{
  position: relative;
}
.right .completed::before{
  content: url('./images/tick-icon.png');
  position: absolute;
  transform: scale(0.5);
  right:-50px;
  top:-6px;
}
.right .non-completed::before{
  content: url('./images/non-completed.png');
  position: absolute;
  transform: scale(0.5);
  right:-50px;
  top:-6px;
}
.left{
  align-items:flex-start;
  margin-left: 60px;
}
.left p{
  position: relative;
}
.left .completed::before{
  content: url('./images/tick-icon.png');
  position: absolute;
  transform: scale(0.5);
  left:-50px;
  top:-6px;
}
.left .non-completed::before{
  content: url('./images/non-completed.png');
  position: absolute;
  transform: scale(0.5);
  left:-50px;
  top:-6px;
}

.rm-content-heading{
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 25px;
}
.rm-content-text{
  font-size: 24px;
  margin-bottom: 0px;
}
.faq-text{
  padding: 4% 0 6%;
  font-size: 26px;
  margin-bottom: 0;
}
.team-section{
  background-color: #2F3A8F;
  padding-bottom: 70px;
}
.team-cards-row{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.team-card{
  flex:0 1 calc(100% - 1em);
  margin: 20px;
  padding:20px;
  text-align: center;
  border:1px solid #f0f3f5;
  background-color:#072227;
}
.card-avatar{
  width: 70px;
  height:70px;
  margin:10px auto;
  background-color: #35858B;
  border-radius: 10%;
}
.card-avatar img{
 width: 100%;
}
.username{
  font-size:22px;
  color:#f0f3f5;
  font-weight: 600;
}
.designation{
  font-size: 18px;
  color:#ffddb4;
}
.team-intro{
  color: #5ac57b;
  font-size: 1rem;
}
.team-icons{
  margin-bottom: 10px;
}
.team-icons a{
  display: inline-block;
  width: 40px;
  height:40px;
  font-size: 22px;
  color: #f0f3f5;
  border-radius: 50%;
  border:1px solid #e9cf3a;
  margin:5px;
  transition: all 0.5s ease-in-out;
}
.team-icons a:hover{
  color:#e9cf3a;
}
.golden-container {
  background-color: #ffddb4;
}

ul {
  padding-left: 0rem !important;
}
.footer-list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 10px;
  justify-content: center;
}
.footer-list li {
  padding-left: 5%;
  padding-right: 5%;
  cursor: pointer;
}

.footer-list li a {
  color: white;
}

.copyright {
  font-size: 20px !important;
}

.footer-container {
  background-color: #38c9ff;
  padding-top: 30px;
  padding-bottom: 15px;
  font-size: 30px;

  color: white;
}

.row-footer {
  width: 90%;
  margin: 0px auto;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}
.copyright p {
  margin: 0 !important;
}

.nav-bar {
  background-color: #38c9ff !important ;
  font-size: 30px;
  padding-left: 10px;
}
.nav-bar-li {
  margin-left: 20px;
  margin-right: 20px;
  color: white !important;
}
.nav-bar-li-icon {
  margin-left: 20px;
  margin-right: 20px;
  color: white !important;
}

.logo-img {
  margin: 0 auto;
  width: 60px;
}

/* the connect button style */
.theConnectButton {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  z-index: 100;
}
.walletInfo {
  display: flex;
  width: 100%;
  /* justify-content: center; */
  align-items: center;
  gap: 12px;
  padding: 1rem 0px;
  flex-wrap: wrap;
}
.walletInfo p {
  width: 40%;
  margin: 0px !important;
}
.WalletInfoInner {
  display: inline-block;
  /* width: 100%; */
  padding: 1rem;
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
  background-color: #effcee; 
  background-image: linear-gradient(183deg, white, transparent);
  text-align: center;
}

.btn-crs-mint{
  width: 100%;
  }

/* Media Querry */
@media only screen and (min-width:1199px){
  .bottom-content{
    bottom: 35%;
    left:25%;
  }
}

@media only screen and (min-width:1099px){
 
}
@media only screen and (min-width: 2400px) {
  .m-text {
    top: 68%;
    left: 24%;
  }
  .main-video {
    width: 1200px;
    height: 600px;
  }
}
@media only screen and (max-width: 2400px) {
  .m-text {
    top: 68%;
    left: 24%;
  }
  .main-video {
    width: 1200px;
    height: 530px;
  }
}
@media only screen and (max-width: 2200px) {
  .m-text {
    top: 68%;
    left: 26%;
  }
  .main-video {
    width: 1000px;
    height: 500px;
  }
}
@media only screen and (max-width: 2048px) {
  .m-text {
    top: 68%;
    left: 25%;
  }
  .main-video {
    width: 1000px;
    height: 500px;
  }
}
@media only screen and (max-width: 1920px) {
  .m-text {
    top: 60%;
    left: 25%;
  }
  .main-video {
    width: 1000px;
    height: 500px;
  }
}
@media only screen and (max-width: 1810px) {
  .m-text {
    top: 60%;
    left: 25%;
  }
  .main-video {
    width: 1000px;
    height: 500px;
  }
  .bottom-content{
    bottom: 35%;
    left:25%;
  }
}
@media only screen and (max-width: 1710px) {
  .m-text {
    top: 60%;
    left: 20%;
  }
  .main-video {
    width: 1000px;
    height: 500px;
  }
}
@media only screen and (max-width: 1610px) {
  .m-text {
    top: 60%;
    left: 25%;
  }
  .main-video {
    width: 750px;
    height: 400px;
  }
}
@media only screen and (max-width: 1419px) {
  .m-text {
    top: 63%;
    left: 25%;
  }
  .main-video {
    width: 700px;
    height: 350px;
  }
  .bottom-content{
    bottom: 31%;
    left:25%;
  }
}
@media only screen and (max-width: 1280px) {
  .main-video {
    width: 700px;
    height: 340px;
  }
  .colection-text {
    height: 320px;
  }
  .m-text {
    left: 23%;
  }
}
@media only screen and (max-width: 1180px) {
  .theConnectButton {
    top: 15px !important;
  }
  .m-text {
    left: 20%;
  }
  .main-video {
    width: 700px;
    height: 300px;
  }
  .bottom-content{
    bottom: 31%;
    left:25%;
  }
}
@media only screen and (max-width: 1130px) {
  .colection-title {
    top: 3%;
  }
  .m-text {
    left: 15%;
  }
  .main-video {
    width: 700px;
    height: 300px;
  }
  .m-text {
    left: 20%;
  }
  .bottom-content{
    bottom: 31%;
    left:25%;
  }
}
@media only screen and (max-width: 1099px) {
  .collection-main-container {
    height: auto;
  }
  .collection-main-container .main-bg {
    width: auto;
  }
  
  
  .team-card{
    flex:0 1 calc(100% - 2em);
    margin:20px 20px;
  }
  .main-video {
    width: 600px;
    height: 260px;
  }
  .m-text {
    top: 68%;
    left: 20%;
  }
}
@media only screen and (max-width: 1000px) {
  .colection-title {
    top: 4%;
  }
  .main-video {
    width: 600px;
    height: 250px;
  }
  .m-text {
    left: 20%;
  }
}

@media only screen and (max-width: 950px) {
  /* .second-main-image{
       height: 100vh;
   } */
  .collection-main-container {
    height: 100vh;
  }
  .collection-main-container .main-bg {
    height: 100vh;
    width: auto;
  }

  .main-video {
    width: 550px;
    height: 250px;
  }
  .m-text {
    left: 20%;
  }

  .colection-title {
    top: 2%;
  }
}
@media only screen and (max-width: 880px) {
  .theConnectButton {
    top: 5px !important;
  }
  .main-video {
    width: 480px;
    height: 230px;
  }
  .m-text {
    left: 20%;
  }
}

@media only screen and (max-width: 770px) {
  .collection-main-container {
    height: auto;
  }
  .collection-main-container .main-bg {
    width: auto;
  }
  .main-image img{
    display: none;
  }
  
  .team-card{
    flex:0 1 calc(100% - 1em);
    margin:20px 20px;
  }
  .main-video {
    width: 450px;
    height: 240px;
  }
  .m-text {
    left: 20%;
  }
  
  .colection-title {
    top: 5%;
    left: 8%;
    font-size: 50px;
  }
  .colection-text {
    padding: 5% 7%;
  }
}

@media only screen and (max-width: 650px) {
  .sc-dlfnuX .MuiButton-contained {
    padding: 12px 10px !important;
    font-size: 1.3rem !important;
  }
  .MuiButton-label {
    line-height: 100%;
  }
  .WalletInfoInner {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .main-video {
    width: 350px;
    height: 210px;
  }
  .m-text {
    left: 20%;
  }
  .colection-title {
    top: 5%;
    left: 8%;
    font-size: 50px;
  }
  
}

@media only screen and (max-width: 556px) {
  .walletInfo p {
    font-size: 0.9rem !important;
  }
  .main-video {
    width: 320px;
    height: 170px;
  }

  .row-footer {
    justify-content: center;
    width: 100%;
    margin: auto;
  }
  .footer-list {
    margin: 0 !important;
  }
  .footer-list li {
    padding-left: 0px;
    padding-right: 0px;
  }

  .m-text {
    width: 200px;
    left: 18%;
  }
  
}
@media only screen and (max-width: 510px) {
  .collection-main-container {
    height: auto;
  }
  .collection-main-container .main-bg {
    width: auto;
  }
  .main-image img{
    display: none;
  }
  
.team-card{
    flex:0 1 calc(100% - 8em);
    margin:20px 30px;
  }
  .WalletInfoInner {
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
 
  .m-text {
    left: 15%;
  }
}
@media only screen and (max-width: 470px) {
 
  
  .m-text {
    left: 15%;
  }
  .main-video {
    width: 300px;
    height: 140px;
  }
}

@media only screen and (max-width: 420px) {
  .main-video {
    width: 300px;
    height: 250px;
  }
  .m-text {
    top: 50% !important;
    left: 8%;
  }
  .colection-title {
    top: 0%;
    font-size: 40px;
  }
  .bottom-content{
    bottom: 20%;
    left:5%;
  }
}

@media only screen and (max-width: 350px) {
  .walletInfo {
    padding-bottom: 10px !important;
  }
  .main-video {
    width: 250px;
    height: 200px;
  }
  .m-text {
    left: 12%;
  }
  
}

.main-container-front {
  height: 100vh;
}
.main-container-front img.main-bg {
  height: 100vh;
  width: auto;
}

/* separate query */
@media only screen and (max-width: 2030px) {
  .main-image-contianer img {
    top: 12%;
  }
}
@media only screen and (min-width: 1926px) {
  .main-image-contianer img {
    top: 19%;
  }
}
@media only screen and (max-width: 1115px) {
  .main-image-contianer img {
    top: 19%;
  }
}
@media only screen and (min-width: 800px) {
  .main-container-front {
    height: auto;
  }
  .main-container-front img.main-bg {
    height: auto;
    width: 100%;
  }
}
@media only screen and (max-width: 740px) {
  .main-image-contianer img {
    top: 26%;
  }
}




/* the connect to wallet button styles */
.MuiButton-contained{
 padding: 15px 20px !important;
 font-size: 2rem !important;
}
.sc-dlfnuX .mint-button{
  color: white !important;
  font-size: 1rem !important;
  padding: 9px 12px !important;
}
.countDownContainer{
    position: absolute;
    top: 1%;
    z-index: 100;
    width: 100%;
    
}
.CountDownLayout{
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 100%;
    
    
}
.progressBarCircle{
    width: 130px;
    
    /* background-color: black; */
}
